import React, { useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import { AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import BillsTable from "./BillsTable";
import { API } from "aws-amplify";
import { listBills } from "../../graphql/queries";
import { calculateBillTotal, currencyFormat } from "../calculationFunctions";
import { BUDGET_AMOUNTS } from "../../budgetAmounts";

const BillsButton = ({ budgetAmount, onUpdate, open, onOpen, onClose }) => {
    useEffect(() => {
        fetchTotalAmountOfBills();
    }, []);

    async function fetchTotalAmountOfBills() {
        const billApiData = await API.graphql({ query: listBills });
        const billExpenses = billApiData.data.listBills.items;
        const totalBillAmount = calculateBillTotal(billExpenses);

        const newBillAmount = BUDGET_AMOUNTS.bills + totalBillAmount;
        onUpdate(newBillAmount);
    }

    return (
        <div>
            <Box textAlign="center">
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined"
                    onClick={onOpen}
                >
                    <Typography sx={{ fontSize: 14 }}>
                        Bills
                        <Typography sx={{ fontSize: 14 }}>
                            {currencyFormat(budgetAmount)} Left
                        </Typography>
                    </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={open}
                onClose={onClose}
                TransitionComponent={Slide}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Bills
                        </Typography>
                    </Toolbar>
                </AppBar>
                <BillsTable />
            </Dialog>
        </div>
    );
};

export default BillsButton;
