import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from "aws-amplify";
import { Flex, View } from "@aws-amplify/ui-react";
import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { convertDate, currencyFormat } from "../calculationFunctions";
import { createBill, deleteBill } from "../../graphql/mutations";
import { listBills } from "../../graphql/queries";
import { BUDGET_AMOUNTS } from "../../budgetAmounts";

export default function BillsTable() {
    const [billExpense, setBillExpense] = useState([]);
    const [amountLeft, setAmountLeft] = useState(0);

    useEffect(() => {
        fetchTotalBills();
    }, []);

    async function fetchTotalBills() {
        const billApiData = await API.graphql({ query: listBills });
        const billExpenses = billApiData.data.listBills.items;
        setBillExpense(billExpenses);

        const totalBillAmount = billExpenses.reduce((total, bill) => total + parseFloat(bill.BillAmount), 0);
        setAmountLeft(BUDGET_AMOUNTS.bills + totalBillAmount);
    }

    async function createBillExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            BillName: form.get("BillName"),
            BillAmount: form.get("BillAmount"),
        };
        await API.graphql({
            query: createBill,
            variables: { input: data },
        });
        await fetchTotalBills();
        event.target.reset();
    }

    async function deleteBillExpense(id) {
        await API.graphql({
            query: deleteBill,
            variables: { input: { id } },
        });
        fetchTotalBills();
    }

    // Function to delete all bills
    async function deleteAllBills() {
        const allBillIds = billExpense.map(bill => bill.id);
        const deletePromises = allBillIds.map(id =>
            API.graphql({
                query: deleteBill,
                variables: { input: { id } },
            })
        );
        try {
            await Promise.all(deletePromises); // Wait for all delete requests to finish
            setBillExpense([]);  // Clear the state after deletion
            await fetchTotalBills();  // Refresh the data
        } catch (error) {
            console.error("Error deleting all bills:", error);
        }
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <Typography textAlign="center" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                Amount Left: {currencyFormat(amountLeft)}
            </Typography>
            <View as="form" onSubmit={createBillExpense}>
                <Flex margin="1rem" direction="row" justifyContent="center">
                    <TextField name="BillName" placeholder="Name" label="Name" required />
                    <TextField name="BillAmount" placeholder="Amount" label="Amount" required />
                    <Button type="submit" variant="contained">Add</Button>
                </Flex>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 350 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                <TableCell align="center">Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {billExpense.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell sx={{ paddingRight: 0 }} align="left">{row.BillName}</TableCell>
                                    <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                    <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.BillAmount)}</TableCell>
                                    <TableCell align="center">
                                        <Button variant="contained" onClick={() => deleteBillExpense(row.id)}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        sx={{ marginTop: 3 }}
                        variant="contained"
                        onClick={deleteAllBills}
                        disabled={billExpense.length === 0}
                    >
                        Delete All Bills
                    </Button>
                </Box>
            </View>

            <div><br /></div>
            <Typography textAlign="center">Cell Phones
                <span style={{fontWeight: 'bold'}}> {currencyFormat(112)}</span>
            </Typography>
            <div><br></br></div>
            <Typography textAlign="center">Car Insurance
                <span style={{fontWeight: 'bold'}}> {currencyFormat(276)}</span>
            </Typography>
            <div><br></br></div>
            <Typography textAlign="center">Truck
                <span style={{fontWeight: 'bold'}}> {currencyFormat(575.75)}</span>
            </Typography>
            <div><br></br></div>
            <Typography textAlign="center">Streaming Services
                <span style={{fontWeight: 'bold'}}> {currencyFormat(13.72)}</span>
            </Typography>
            <div><br></br></div>
            <Typography textAlign="center">Amazon
                <span style={{fontWeight: 'bold'}}> {currencyFormat(33.08)}</span>
            </Typography>
            <div><br></br></div>
            <Typography textAlign="center">Tolls
                <span style={{fontWeight: 'bold'}}> {currencyFormat(130)}</span>
            </Typography>
            <div><br></br></div>
            <Typography textAlign="center">Internet
                <span style={{fontWeight: 'bold'}}> {currencyFormat(60.53)}</span>
            </Typography>
            <div><br></br></div>
            <Typography textAlign="center">Life Insurance
                <span style={{fontWeight: 'bold'}}> {currencyFormat(200)}</span>
            </Typography>
            <div><br></br></div>
            <Typography textAlign="center">4Engergy
                <span style={{fontWeight: 'bold'}}> {currencyFormat(245)}</span>
            </Typography>
            <div><br></br></div>
            <Typography textAlign="center">Atmos Gas
                <span style={{fontWeight: 'bold'}}> {currencyFormat(120)}</span>
            </Typography>
            <div><br></br></div>
            <Typography textAlign="center">CrossRoads Water/Sewer
                <span style={{fontWeight: 'bold'}}> {currencyFormat(80)}</span>
            </Typography>
            <div><br /></div>
            <Typography textAlign="center">
                Amount to carry over at the end of the month is
                <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                    {" " + currencyFormat(amountLeft)}
                </Typography>
            </Typography>
            <div><br /></div>
            <Typography textAlign="center">***Monthly budget for Bills is {currencyFormat(BUDGET_AMOUNTS.bills)}</Typography>
        </Box>
    );
}
