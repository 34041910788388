import { API } from 'aws-amplify';

export async function handleCreateExpense(event, createMutation, fetchCallback, fieldNames) {
    event.preventDefault();
    const form = new FormData(event.target);

    const data = {
        [fieldNames.name]: form.get(fieldNames.name),
        [fieldNames.amount]: parseFloat(form.get(fieldNames.amount))
    };

    try {
        await API.graphql({
            query: createMutation,
            variables: { input: data }
        });
        fetchCallback();
        event.target.reset();
    } catch (error) {
        console.error("Error creating expense:", error);
    }
}

export async function deleteAllExpenses(expenseList, deleteMutation, setState, fetchCallback) {
    const allExpenseIds = expenseList.map(expense => expense.id);
    const deletePromises = allExpenseIds.map(id =>
        API.graphql({
            query: deleteMutation,
            variables: { input: { id } },
        })
    );

    try {
        await Promise.all(deletePromises);
        setState([]);
        await fetchCallback();
    } catch (error) {
        console.error("Error deleting expenses:", error);
    }
}
