import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from "aws-amplify";
import {
    listBriGasExpenses,
    listGasFunds,
    listJustinGasExpenses
} from "../../graphql/queries";
import {
    createBriGasExpense,
    createGasFund,
    createJustinGasExpense,
    deleteBriGasExpense,
    deleteGasFund,
    deleteJustinGasExpense
} from "../../graphql/mutations";
import { Flex, View } from "@aws-amplify/ui-react";
import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
    calculateBriGasTotal,
    calculateGasFundTotal,
    calculateJustinGasTotal,
    convertDate,
    currencyFormat
} from "../calculationFunctions";
import { BUDGET_AMOUNTS } from "../../budgetAmounts";
import { handleCreateExpense } from "../expenseHelpers";

export default function GasTable() {
    const [gasBriExpense, setGasBriExpenses] = useState([]);
    const [gasJustinExpense, setGasJustinExpenses] = useState([]);
    const [gasFund, setGasFund] = useState([]);
    const [value, setValue] = useState('1');
    const [gasAmount, setGasAmount] = useState(0);

    useEffect(() => {
        fetchTotalAmountofGas();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalAmountofGas() {
        const [briApiData, justinApiData, fundApiData] = await Promise.all([
            API.graphql({ query: listBriGasExpenses }),
            API.graphql({ query: listJustinGasExpenses }),
            API.graphql({ query: listGasFunds })
        ]);

        const briExpenses = briApiData.data.listBriGasExpenses.items;
        const justinExpenses = justinApiData.data.listJustinGasExpenses.items;
        const fundExpenses = fundApiData.data.listGasFunds.items;

        setGasBriExpenses(briExpenses);
        setGasJustinExpenses(justinExpenses);
        setGasFund(fundExpenses);

        const totalFund = calculateGasFundTotal(fundExpenses);
        const totalJustin = calculateJustinGasTotal(justinExpenses);
        const totalBri = calculateBriGasTotal(briExpenses);

        setGasAmount(BUDGET_AMOUNTS.gas + totalFund + totalJustin + totalBri);
    }

    async function handleDeleteExpense(id, deleteExpense, updateState) {
        await API.graphql({ query: deleteExpense, variables: { input: { id } } });
        updateState(prev => prev.filter(expense => expense.id !== id));
        fetchTotalAmountofGas();
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Gas Fund" value="1" />
                        <Tab label="Bri" value="2" />
                        <Tab label="Justin" value="3" />
                    </TabList>
                </Box>
                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="1">
                    <Typography textAlign="center">Total Gas Fund for the month is
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                            {" " + currencyFormat(gasAmount)}
                        </Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createGasFund, fetchTotalAmountofGas, { name: "GasFundName", amount: "GasFundAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="GasFundName" placeholder="Name" label="Name" required />
                            <TextField name="GasFundAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {gasFund.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.GasFundName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.GasFundAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteGasFund, setGasFund)}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="2">
                    <Typography textAlign="center">Total amount Bri has spent
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{" " + currencyFormat(-calculateBriGasTotal(gasBriExpense))}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createBriGasExpense, fetchTotalAmountofGas, { name: "BriGasExpenseName", amount: "BriGasExpenseAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="BriGasExpenseName" placeholder="Name" label="Name" required />
                            <TextField name="BriGasExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {gasBriExpense.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.BriGasExpenseName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.BriGasExpenseAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteBriGasExpense, setGasBriExpenses)}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="3">
                    <Typography textAlign="center">Total amount Justin has spent
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{" " + currencyFormat(-calculateJustinGasTotal(gasJustinExpense))}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createJustinGasExpense, fetchTotalAmountofGas, { name: "JustinGasExpenseName", amount: "JustinGasExpenseAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="JustinGasExpenseName" placeholder="Name" label="Name" required />
                            <TextField name="JustinGasExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {gasJustinExpense.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.JustinGasExpenseName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.JustinGasExpenseAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteJustinGasExpense, setGasJustinExpenses)}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br /></div>
                <Typography textAlign="center">
                    Amount to carry over at the end of the month is
                    <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                        {" " + currencyFormat(gasAmount)}
                    </Typography>
                </Typography>
                <div><br /></div>
                <Typography textAlign="center">***Monthly budget for Gas is {currencyFormat(BUDGET_AMOUNTS.gas)}</Typography>
            </TabContext>
        </Box>
    );
}
