import React, { useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import { API } from "aws-amplify";
import {
    listBriIanExpenses,
    listIanFunds,
    listJustinIanExpenses
} from "../../graphql/queries";
import {
    AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {
    calculateBriIanAllowanceTotal,
    calculateIanFundTotal,
    calculateJustinIanAllowanceTotal,
    currencyFormat
} from "../calculationFunctions";
import IanAllowanceTable from "../../components/IanAllowance/IanAllowanceTable";
import { BUDGET_AMOUNTS } from "../../budgetAmounts";

const IanAllowanceButton = ({ budgetAmount, onUpdate, open, onOpen, onClose }) => {
    useEffect(() => {
        fetchTotalAmountofIanAllowance();
    }, []);

    async function fetchTotalAmountofIanAllowance() {
        const [briApiData, justinApiData, fundApiData] = await Promise.all([
            API.graphql({ query: listBriIanExpenses }),
            API.graphql({ query: listJustinIanExpenses }),
            API.graphql({ query: listIanFunds })
        ]);

        const totalFund = calculateIanFundTotal(fundApiData.data.listIanFunds.items);
        const totalJustin = calculateJustinIanAllowanceTotal(justinApiData.data.listJustinIanExpenses.items);
        const totalBri = calculateBriIanAllowanceTotal(briApiData.data.listBriIanExpenses.items);

        const newIanAllowanceAmount = BUDGET_AMOUNTS.ianAllowance + totalFund + totalJustin + totalBri;
        onUpdate(newIanAllowanceAmount);
    }

    return (
        <div>
            <Box textAlign="center">
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined"
                    onClick={onOpen}
                >
                    <Typography sx={{ fontSize: 14 }}>
                        Ian's Allowance
                        <Typography sx={{ fontSize: 14 }}>
                            {currencyFormat(budgetAmount)} Left
                        </Typography>
                    </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={open}
                onClose={onClose}
                TransitionComponent={Slide}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Ian's Allowance
                        </Typography>
                    </Toolbar>
                </AppBar>
                <IanAllowanceTable />
            </Dialog>
        </div>
    );
};

export default IanAllowanceButton;
