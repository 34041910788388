import React, { useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import { API } from "aws-amplify";
import { listBriGasExpenses, listGasFunds, listJustinGasExpenses } from "../../graphql/queries";
import { AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {
    calculateBriGasTotal,
    calculateGasFundTotal,
    calculateJustinGasTotal,
    currencyFormat
} from "../calculationFunctions";
import GasTable from "./GasTable";
import { BUDGET_AMOUNTS } from "../../budgetAmounts";

const GasButton = ({ budgetAmount, onUpdate, open, onOpen, onClose }) => {
    useEffect(() => {
        fetchTotalAmountofGas();
    }, []);

    async function fetchTotalAmountofGas() {
        const briApiData = await API.graphql({ query: listBriGasExpenses });
        const justinApiData = await API.graphql({ query: listJustinGasExpenses });
        const fundApiData = await API.graphql({ query: listGasFunds });

        const totalFund = calculateGasFundTotal(fundApiData.data.listGasFunds.items);
        const totalJustin = calculateJustinGasTotal(justinApiData.data.listJustinGasExpenses.items);
        const totalBri = calculateBriGasTotal(briApiData.data.listBriGasExpenses.items);

        const newGasAmount = BUDGET_AMOUNTS.gas + (totalFund + totalJustin + totalBri);
        onUpdate(newGasAmount);
    }

    return (
        <div>
            <Box textAlign="center">
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined"
                    onClick={onOpen}
                >
                    <Typography sx={{ fontSize: 14 }}>
                        Gas
                        <Typography sx={{ fontSize: 14 }}>
                            {currencyFormat(budgetAmount)} Left
                        </Typography>
                    </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={open}
                onClose={onClose}
                TransitionComponent={Slide}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Gas
                        </Typography>
                    </Toolbar>
                </AppBar>
                <GasTable />
            </Dialog>
        </div>
    );
};

export default GasButton;
