import React, { useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import { AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { API } from "aws-amplify";
import { listBriTravelExpenses, listJustinTravelExpenses, listTravelAmounts } from "../../graphql/queries";
import {
    calculateBriTravelExpenseTotal,
    calculateJustinTravelExpenseTotal,
    calculateTravelExpenseTotal,
    currencyFormat
} from "../calculationFunctions";
import TravelTable from "./TravelTable";
import { BUDGET_AMOUNTS } from "../../budgetAmounts";

const TravelButton = ({ budgetAmount, onUpdate, open, onOpen, onClose }) => {

    useEffect(() => {
        fetchTotalAmountofTravel();
    }, []);

    async function fetchTotalAmountofTravel() {
        const [briApiData, justinApiData, fundApiData] = await Promise.all([
            API.graphql({ query: listBriTravelExpenses }),
            API.graphql({ query: listJustinTravelExpenses }),
            API.graphql({ query: listTravelAmounts })
        ]);

        const totalFund = calculateTravelExpenseTotal(fundApiData.data.listTravelAmounts.items);
        const totalJustin = calculateJustinTravelExpenseTotal(justinApiData.data.listJustinTravelExpenses.items);
        const totalBri = calculateBriTravelExpenseTotal(briApiData.data.listBriTravelExpenses.items);

        const newTravelAmount = BUDGET_AMOUNTS.travel + totalFund + totalJustin + totalBri;
        onUpdate(newTravelAmount);
    }

    return (
        <div>
            <Box textAlign='center'>
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined"
                    onClick={onOpen}
                >
                    <Typography sx={{ fontSize: 14 }}>
                        Travel Fund
                        <Typography sx={{ fontSize: 14 }}>
                            {currencyFormat(budgetAmount)} Total
                        </Typography>
                    </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={open}
                onClose={onClose}
                TransitionComponent={Slide}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Travel Fund
                        </Typography>
                    </Toolbar>
                </AppBar>
                <TravelTable />
            </Dialog>
        </div>
    );
};

export default TravelButton;
