import React, { useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import { AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EmergencySavingsTable from "./EmergencySavingsTable";
import { API } from "aws-amplify";
import { listBriEmergencyExpenses, listEmergencySavingsAmounts, listJustinEmergencyExpenses } from "../../graphql/queries";
import { calculateBriEmergencyExpenseTotal, calculateEmergencyExpenseTotal, calculateJustinEmergencyExpenseTotal, currencyFormat } from "../calculationFunctions";
import { BUDGET_AMOUNTS } from "../../budgetAmounts";

const EmergencySavingsButton = ({ budgetAmount, onUpdate, open, onOpen, onClose }) => {
    useEffect(() => {
        fetchEmergencySavingsItems();
    }, []);

    async function fetchEmergencySavingsItems() {
        const [emergencySavingsData, briData, justinData] = await Promise.all([
            API.graphql({ query: listEmergencySavingsAmounts }),
            API.graphql({ query: listBriEmergencyExpenses }),
            API.graphql({ query: listJustinEmergencyExpenses })
        ]);

        const amountsFromAPI = emergencySavingsData.data.listEmergencySavingsAmounts.items;
        const amountsFromBriAPI = briData.data.listBriEmergencyExpenses.items;
        const amountsFromJustinAPI = justinData.data.listJustinEmergencyExpenses.items;

        const totalAmount = calculateEmergencyExpenseTotal(amountsFromAPI);
        const totalBriAmount = calculateBriEmergencyExpenseTotal(amountsFromBriAPI);
        const totalJustinAmount = calculateJustinEmergencyExpenseTotal(amountsFromJustinAPI);

        const newTotalAmount = BUDGET_AMOUNTS.emergency + totalAmount + totalBriAmount + totalJustinAmount;
        onUpdate(newTotalAmount);
    }

    return (
        <div>
            <Box textAlign="center">
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined"
                    onClick={onOpen}
                >
                    <Typography sx={{ fontSize: 14 }}>
                        Emergency Savings
                        <Typography sx={{ fontSize: 14 }}>
                            {currencyFormat(budgetAmount)} Total
                        </Typography>
                    </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={open}
                onClose={onClose}
                TransitionComponent={Slide}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Emergency Savings
                        </Typography>
                    </Toolbar>
                </AppBar>
                <EmergencySavingsTable />
            </Dialog>
        </div>
    );
};

export default EmergencySavingsButton;
