import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from "aws-amplify";
import { Flex, View } from "@aws-amplify/ui-react";
import { Button, TextField, Typography } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import {
    calculateBriTravelExpenseTotal,
    calculateJustinTravelExpenseTotal,
    calculateTravelExpenseTotal,
    convertDate,
    currencyFormat
} from "../calculationFunctions";
import {
    createBriTravelExpense,
    createTravelAmount,
    deleteTravelAmount,
    deleteBriTravelExpense,
    createJustinTravelExpense,
    deleteJustinTravelExpense
} from "../../graphql/mutations";
import { listBriTravelExpenses, listJustinTravelExpenses, listTravelAmounts } from "../../graphql/queries";
import { BUDGET_AMOUNTS } from "../../budgetAmounts";
import { handleCreateExpense } from "../expenseHelpers"; // Import the dynamic create function
import Box from "@mui/material/Box";

export default function TravelTable() {
    const [travelExpense, setTravelExpenses] = useState([]);
    const [briTravelExpense, setBriTravelExpenses] = useState([]);
    const [justinTravelExpense, setJustinTravelExpenses] = useState([]);
    const [value, setValue] = useState('1');
    const [finalTotal, setFinalTotal] = useState(0);

    useEffect(() => {
        fetchTotalTravelFund();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalTravelFund() {
        const [apiData, apiBriData, apiJustinData] = await Promise.all([
            API.graphql({ query: listTravelAmounts }),
            API.graphql({ query: listBriTravelExpenses }),
            API.graphql({ query: listJustinTravelExpenses })
        ]);

        const travelExpenses = apiData.data.listTravelAmounts.items;
        const briExpenses = apiBriData.data.listBriTravelExpenses.items;
        const justinExpenses = apiJustinData.data.listJustinTravelExpenses.items;

        setTravelExpenses(travelExpenses);
        setBriTravelExpenses(briExpenses);
        setJustinTravelExpenses(justinExpenses);

        const totalFund = calculateTravelExpenseTotal(travelExpenses);
        const totalBri = calculateBriTravelExpenseTotal(briExpenses);
        const totalJustin = calculateJustinTravelExpenseTotal(justinExpenses);

        setFinalTotal(BUDGET_AMOUNTS.travel + totalFund + totalBri + totalJustin);
    }

    async function handleDeleteExpense(id, deleteExpense, updateState) {
        await API.graphql({ query: deleteExpense, variables: { input: { id } } });
        updateState(prev => prev.filter(expense => expense.id !== id));
        fetchTotalTravelFund();
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="travel tabs">
                        <Tab label="Travel Fund" value="1" />
                        <Tab label="Bri" value="2" />
                        <Tab label="Justin" value="3" />
                    </TabList>
                </Box>
                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="1">
                    <Typography textAlign="center">
                        Total Travel Fund for the month is
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                            {" " + currencyFormat(finalTotal)}
                        </Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createTravelAmount, fetchTotalTravelFund, { name: "TravelName", amount: "TravelAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="TravelName" placeholder="Name" label="Name" required />
                            <TextField name="TravelAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {travelExpense.map(row => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.TravelName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.TravelAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteTravelAmount, setTravelExpenses)}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="2">
                    <Typography textAlign="center">Total amount Bri has spent
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{" " + currencyFormat(-calculateBriTravelExpenseTotal(briTravelExpense))}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createBriTravelExpense, fetchTotalTravelFund, { name: "BriTravelExpenseName", amount: "BriTravelExpenseAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="BriTravelExpenseName" placeholder="Name" label="Name" required />
                            <TextField name="BriTravelExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {briTravelExpense.map(row => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.BriTravelExpenseName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.BriTravelExpenseAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteBriTravelExpense, setBriTravelExpenses)}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="3">
                    <Typography textAlign="center">Total amount Justin has spent
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{" " + currencyFormat(-calculateJustinTravelExpenseTotal(justinTravelExpense))}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createJustinTravelExpense, fetchTotalTravelFund, { name: "JustinTravelExpenseName", amount: "JustinTravelExpenseAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="JustinTravelExpenseName" placeholder="Name" label="Name" required />
                            <TextField name="JustinTravelExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {justinTravelExpense.map(row => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.JustinTravelExpenseName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.JustinTravelExpenseAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteJustinTravelExpense, setJustinTravelExpenses)}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br /></div>
                <Typography textAlign="center">
                    Amount to carry over at the end of the month is
                    <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                        {" " + currencyFormat(finalTotal)}
                    </Typography>
                </Typography>
                <div><br /></div>
                <Typography textAlign="center">***Monthly contribution to Travel is {currencyFormat(BUDGET_AMOUNTS.travel)}</Typography>
            </TabContext>
        </Box>
    );
}
