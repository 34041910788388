import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from "aws-amplify";
import {
    listBriGroceryExpenses,
    listGroceryFunds,
    listJustinGroceryExpenses
} from "../../graphql/queries";
import {
    createBriGroceryExpense,
    createGroceryFund,
    createJustinGroceryExpense,
    deleteBriGroceryExpense,
    deleteGroceryFund,
    deleteJustinGroceryExpense
} from "../../graphql/mutations";
import { Flex, View } from "@aws-amplify/ui-react";
import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
    calculateBriGroceryTotal,
    calculateGroceryFundTotal,
    calculateJustinGroceryTotal,
    convertDate,
    currencyFormat
} from "../calculationFunctions";
import {BUDGET_AMOUNTS} from "../../budgetAmounts";
import {handleCreateExpense} from "../expenseHelpers";

export default function GroceryTable() {
    const [groceryBriExpense, setGroceryBriExpenses] = useState([]);
    const [groceryJustinExpense, setGroceryJustinExpenses] = useState([]);
    const [groceryFund, setGroceryFund] = useState([]);
    const [value, setValue] = useState('1');
    const [amountToCarryOver, setAmountToCarryOver] = useState(0);

    useEffect(() => {
        fetchTotalAmountofGroceries();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalAmountofGroceries() {
        try {
            const [briApiData, justinApiData, fundApiData] = await Promise.all([
                API.graphql({ query: listBriGroceryExpenses }),
                API.graphql({ query: listJustinGroceryExpenses }),
                API.graphql({ query: listGroceryFunds })
            ]);

            const briExpenses = briApiData.data.listBriGroceryExpenses.items;
            const justinExpenses = justinApiData.data.listJustinGroceryExpenses.items;
            const fundExpenses = fundApiData.data.listGroceryFunds.items;

            setGroceryBriExpenses(briExpenses);
            setGroceryJustinExpenses(justinExpenses);
            setGroceryFund(fundExpenses);

            const totalFund = calculateGroceryFundTotal(fundExpenses);
            const totalJustin = calculateJustinGroceryTotal(justinExpenses);
            const totalBri = calculateBriGroceryTotal(briExpenses);

            setAmountToCarryOver(BUDGET_AMOUNTS.groceries + totalFund + totalJustin + totalBri);
        } catch (error) {
            console.error("Error fetching grocery data:", error);
        }
    }

    async function handleDeleteExpense(id, deleteExpense, updateState) {
        await API.graphql({ query: deleteExpense, variables: { input: { id } } });
        updateState((prev) => prev.filter((expense) => expense.id !== id));
        fetchTotalAmountofGroceries();
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Grocery Fund" value="1" />
                        <Tab label="Bri" value="2" />
                        <Tab label="Justin" value="3" />
                    </TabList>
                </Box>
                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="1">
                    <Typography textAlign="center">Total Grocery Fund<br /> for the month is
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}> {currencyFormat(amountToCarryOver)}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createGroceryFund, fetchTotalAmountofGroceries, { name: 'GroceriesFundName', amount: 'GroceriesFundAmount' })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="GroceriesFundName" placeholder="Name" label="Name" required />
                            <TextField name="GroceriesFundAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{paddingRight: 0}} align="left">Expense</TableCell>
                                        <TableCell sx={{paddingLeft: .5}} align="left">Day</TableCell>
                                        <TableCell sx={{paddingLeft: 1}} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groceryFund.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{paddingRight: 0}} align="left">{row.GroceriesFundName}</TableCell>
                                            <TableCell sx={{paddingLeft: .5}} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{paddingLeft: 0}}  align="center">{currencyFormat(row.GroceriesFundAmount)}</TableCell>
                                           <TableCell><Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteGroceryFund, setGroceryFund)}>Delete</Button></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="2">
                    <Typography textAlign="center">Total amount Bri has spent
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{" " + currencyFormat(-calculateBriGroceryTotal(groceryBriExpense))}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createBriGroceryExpense, fetchTotalAmountofGroceries, { name: 'BriGroceryExpenseName', amount: 'BriGroceriesExpenseAmount' })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="BriGroceryExpenseName" placeholder="Name" label="Name" required />
                            <TextField name="BriGroceriesExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{paddingRight: 0}} align="left">Expense</TableCell>
                                        <TableCell sx={{paddingLeft: .5}} align="left">Day</TableCell>
                                        <TableCell sx={{paddingLeft: 1}} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groceryBriExpense.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{paddingRight: 0}} align="left">{row.BriGroceryExpenseName}</TableCell>
                                            <TableCell sx={{paddingLeft: .5}} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{paddingLeft: 1}} align="center">{currencyFormat(row.BriGroceriesExpenseAmount)}</TableCell>
                                            <TableCell><Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteBriGroceryExpense, setGroceryBriExpenses)}>Delete</Button></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="3">
                    <Typography textAlign="center">Total amount Justin has spent
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{" " + currencyFormat(-calculateJustinGroceryTotal(groceryJustinExpense))}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createJustinGroceryExpense, fetchTotalAmountofGroceries, { name: 'JustinGroceriesExpenseName', amount: 'JustinGroceriesExpenseAmount' })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="JustinGroceriesExpenseName" placeholder="Name" label="Name" required />
                            <TextField name="JustinGroceriesExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{paddingRight: 0}} align="left">Expense</TableCell>
                                        <TableCell sx={{paddingLeft: .5}} align="left">Day</TableCell>
                                        <TableCell sx={{paddingLeft: 1}} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groceryJustinExpense.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{paddingRight: 0}} align="left">{row.JustinGroceriesExpenseName}</TableCell>
                                            <TableCell sx={{paddingLeft: .5}} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{paddingLeft: 1}} align="center">{currencyFormat(row.JustinGroceriesExpenseAmount)}</TableCell>
                                           <TableCell><Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteJustinGroceryExpense, setGroceryJustinExpenses)}>Delete</Button></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br /></div>
                <Typography textAlign="center">Amount to carry over at the end<br /> of the month is
                    <Typography sx={{ fontWeight: 'bold', display: 'inline' }}> {currencyFormat(amountToCarryOver)}</Typography>
                </Typography>
                <div><br /></div>
                <Typography textAlign="center">**Monthly budget for Groceries is {currencyFormat(BUDGET_AMOUNTS.groceries)}</Typography>
                <div><br /></div>
                <div><br /></div>
            </TabContext>
        </Box>
    );
}
