import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from "aws-amplify";
import {
    listBriExtrasExpenses,
    listExtrasFunds,
    listJustinExtrasExpenses
} from "../../graphql/queries";
import {
    createBriExtrasExpense,
    createExtrasFund,
    createJustinExtrasExpense,
    deleteBriExtrasExpense,
    deleteExtrasFund,
    deleteJustinExtrasExpense
} from "../../graphql/mutations";
import { Flex, View } from "@aws-amplify/ui-react";
import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
    calculateBriExtrasTotal,
    calculateExtraFundTotal,
    calculateJustinExtrasTotal,
    convertDate,
    currencyFormat
} from "../calculationFunctions";
import { BUDGET_AMOUNTS } from "../../budgetAmounts";
import { handleCreateExpense } from "../expenseHelpers"; // Import the dynamic create function

export default function ExtrasTable() {
    const [extrasBriExpense, setExtrasBriExpenses] = useState([]);
    const [extrasJustinExpense, setExtrasJustinExpenses] = useState([]);
    const [extrasFund, setExtrasFund] = useState([]);
    const [value, setValue] = useState('1');
    const [extrasAmount, setExtrasAmount] = useState(0);

    useEffect(() => {
        fetchTotalAmountofExtras();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalAmountofExtras() {
        const [briApiData, justinApiData, fundApiData] = await Promise.all([
            API.graphql({ query: listBriExtrasExpenses }),
            API.graphql({ query: listJustinExtrasExpenses }),
            API.graphql({ query: listExtrasFunds })
        ]);

        const briExpenses = briApiData.data.listBriExtrasExpenses.items;
        const justinExpenses = justinApiData.data.listJustinExtrasExpenses.items;
        const fundExpenses = fundApiData.data.listExtrasFunds.items;

        setExtrasBriExpenses(briExpenses);
        setExtrasJustinExpenses(justinExpenses);
        setExtrasFund(fundExpenses);

        const totalFund = calculateExtraFundTotal(fundExpenses);
        const totalJustin = calculateJustinExtrasTotal(justinExpenses);
        const totalBri = calculateBriExtrasTotal(briExpenses);

        setExtrasAmount(BUDGET_AMOUNTS.extras + totalFund + totalJustin + totalBri);
    }

    async function handleDeleteExpense(id, deleteExpense, updateState) {
        await API.graphql({ query: deleteExpense, variables: { input: { id } } });
        updateState(prev => prev.filter(expense => expense.id !== id));
        fetchTotalAmountofExtras();
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Extra Fund" value="1" />
                        <Tab label="Bri" value="2" />
                        <Tab label="Justin" value="3" />
                    </TabList>
                </Box>
                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="1">
                    <Typography textAlign="center">
                        Total Extras Fund for the month is
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}> {currencyFormat(extrasAmount)}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createExtrasFund, fetchTotalAmountofExtras, { name: "ExtrasFundName", amount: "ExtrasFundExpenseAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="ExtrasFundName" placeholder="Name" label="Name" required />
                            <TextField name="ExtrasFundExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {extrasFund.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.ExtrasFundName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.ExtrasFundExpenseAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteExtrasFund, setExtrasFund)}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="2">
                    <Typography textAlign="center">
                        Total amount Bri has spent
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{" " + currencyFormat(-calculateBriExtrasTotal(extrasBriExpense))}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createBriExtrasExpense, fetchTotalAmountofExtras, { name: "BriExtrasExpenseName", amount: "BriExtrasExpenseAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="BriExtrasExpenseName" placeholder="Name" label="Name" required />
                            <TextField name="BriExtrasExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {extrasBriExpense.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.BriExtrasExpenseName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.BriExtrasExpenseAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteBriExtrasExpense, setExtrasBriExpenses)}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="3">
                    <Typography textAlign="center">
                        Total amount Justin has spent
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{" " + currencyFormat(-calculateJustinExtrasTotal(extrasJustinExpense))}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createJustinExtrasExpense, fetchTotalAmountofExtras, { name: "JustinExtrasName", amount: "JustinExtrasExpenseAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="JustinExtrasName" placeholder="Name" label="Name" required />
                            <TextField name="JustinExtrasExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {extrasJustinExpense.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.JustinExtrasName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.JustinExtrasExpenseAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteJustinExtrasExpense, setExtrasJustinExpenses)}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <div><br /></div>
                <Typography textAlign="center">
                    Amount to carry over at the end of the month is
                    <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                        {" " + currencyFormat(extrasAmount)}
                    </Typography>
                </Typography>
                <div><br /></div>
                <Typography textAlign="center">***Monthly budget for Extras is {currencyFormat(BUDGET_AMOUNTS.extras)}</Typography>
            </TabContext>
        </Box>
    );
}
