import React, { useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import { API } from "aws-amplify";
import {
    listBriEntertainmentExpenses,
    listEntertainmentFunds,
    listJustinEntertainmentExpenses,
} from "../../graphql/queries";
import { AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {
    calculateBriEntertainmentTotal, calculateEntertainmentFundTotal,
    calculateJustinEntertainmentTotal,
    currencyFormat,
} from "../calculationFunctions";
import EntertainmentTable from "../../components/Entertainment/EntertainmentTable";
import {BUDGET_AMOUNTS} from "../../budgetAmounts";

const EntertainmentButton = ({ budgetAmount, onUpdate, open, onOpen, onClose }) => {
    useEffect(() => {
        fetchTotalAmountofEntertainment();
    }, []);

    async function fetchTotalAmountofEntertainment() {
        const [briApiData, justinApiData, fundApiData] = await Promise.all([
            API.graphql({ query: listBriEntertainmentExpenses }),
            API.graphql({ query: listJustinEntertainmentExpenses }),
            API.graphql({ query: listEntertainmentFunds })
        ]);

        const totalFund = calculateEntertainmentFundTotal(fundApiData.data.listEntertainmentFunds.items);
        const totalJustin = calculateJustinEntertainmentTotal(justinApiData.data.listJustinEntertainmentExpenses.items);
        const totalBri = calculateBriEntertainmentTotal(briApiData.data.listBriEntertainmentExpenses.items);

        const newEntertainmentAmount = BUDGET_AMOUNTS.entertainment + totalFund + totalJustin + totalBri;
        onUpdate(newEntertainmentAmount);
    }

    return (
        <div>
            <Box textAlign="center">
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined"
                    onClick={onOpen}
                >
                    <Typography sx={{ fontSize: 14 }}>
                        Entertainment
                        <Typography sx={{ fontSize: 14 }}>
                            {currencyFormat(budgetAmount)} Left
                        </Typography>
                    </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={open}
                onClose={onClose}
                TransitionComponent={Slide}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Entertainment
                        </Typography>
                    </Toolbar>
                </AppBar>
                <EntertainmentTable />
            </Dialog>
        </div>
    );
};

export default EntertainmentButton;
