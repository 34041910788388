import React, { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { API, Auth } from 'aws-amplify';
import Box from "@mui/material/Box";
import {Button} from "@mui/material";

const PlaidLinkComponent = () => {
    const [linkToken, setLinkToken] = useState(null);

    // Function to create the Plaid link token
    const createPlaidLinkToken = async (userId) => {
        try {
            // Call your Lambda API to generate the Plaid link token
            const response = await API.post('plaid', '/items', {
                body: { userId }, // Send the user ID to your Lambda function
            });
            if (response && response.link_token) {
                return response.link_token;
            } else {
                console.error('Error creating Plaid Link token:', response.error);
                return null;
            }
        } catch (error) {
            console.error('Error in Plaid token creation:', error);
            return null;
        }
    };

    // Fetch the user ID (from Amplify Auth) and generate the link token on component mount
    const fetchUserId = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const userId = user.username; // Use the username or a unique identifier from Cognito
            const token = await createPlaidLinkToken(userId);
            setLinkToken(token);
        } catch (error) {
            console.error('Error fetching user ID:', error);
        }
    };

    useEffect(() => {
        // This IIFE (Immediately Invoked Function Expression) ensures that no promise is returned from useEffect
        (async () => {
            await fetchUserId();
        })();

    }, []);


    // Handle successful Plaid Link connection
    const onSuccess = useCallback(async (publicToken) => {
        try {
            const response = await API.post('plaid', '/items', {
                body: { publicToken },
            });
            console.log('Transactions:', response.transactions);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    }, []);

    // Config for Plaid link
    const config = {
        token: linkToken, // Use the link token generated from the backend
        onSuccess,
    };

    const { open, ready } = usePlaidLink(config);

    if (!linkToken) {
        return(
        <Box textAlign="center">
        <Button
            sx={{
                color: 'white',
                bgcolor: '#4417c6',
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                mt: 4,
                minWidth: 300,
            }}
            variant="outlined"
        >Loading Link...</Button>;
        </Box>
        )
    }
    return (
        <Box textAlign="center">
            <Button
                sx={{
                    color: 'white',
                    bgcolor: '#4417c6',
                    boxShadow: 1,
                    borderRadius: 2,
                    p: 2,
                    mt: 4,
                    minWidth: 300,
                }}
                variant="outlined"
                onClick={() => open()} disabled={!ready}
            >Link Your Bank</Button>;
        </Box>
    );
};

export default PlaidLinkComponent;
