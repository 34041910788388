export const BUDGET_AMOUNTS = {
    groceries: 950.00,
    gas: 300,
    travel: 200.00,
    emergency: 300.00,
    extras: 650.00,
    entertainment: 450.00,
    ianAllowance: 40.00,
    justinAllowance: 225.00,
    briAllowance: 125.00,
    bills: 1846.08
};
