import React, { useEffect, useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import { API } from "aws-amplify";
import {
    listBriExtrasExpenses,
    listExtrasFunds,
    listJustinExtrasExpenses,
} from "../../graphql/queries";
import { AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ExtrasTable from "./ExtrasTable";
import {
    calculateBriExtrasTotal,
    calculateExtraFundTotal,
    calculateJustinExtrasTotal,
    currencyFormat,
} from "../calculationFunctions";
import { BUDGET_AMOUNTS } from "../../budgetAmounts";

const ExtrasButton = () => {
    const [extrasOpen, setExtrasOpen] = useState(false);
    const [extrasAmount, setExtrasAmount] = useState(0);

    useEffect(() => {
        fetchTotalAmountofExtras();
    }, []);

    const handleClickExtrasOpen = () => {
        setExtrasOpen(true);
    };

    const handleExtrasClose = () => {
        setExtrasOpen(false);
    };

    async function fetchTotalAmountofExtras() {
        try {
            const [briApiData, justinApiData, fundApiData] = await Promise.all([
                API.graphql({ query: listBriExtrasExpenses }),
                API.graphql({ query: listJustinExtrasExpenses }),
                API.graphql({ query: listExtrasFunds })
            ]);

            const briExpenses = briApiData.data.listBriExtrasExpenses.items;
            const justinExpenses = justinApiData.data.listJustinExtrasExpenses.items;
            const fundExpenses = fundApiData.data.listExtrasFunds.items;

            const totalFund = calculateExtraFundTotal(fundExpenses);
            const totalJustin = calculateJustinExtrasTotal(justinExpenses);
            const totalBri = calculateBriExtrasTotal(briExpenses);

            setExtrasAmount(BUDGET_AMOUNTS.extras + totalFund + totalJustin + totalBri);
        } catch (error) {
            console.error("Error fetching extras data:", error);
        }
    }

    return (
        <div>
            <Box textAlign="center">
                <Button
                    sx={{
                        color: "white",
                        bgcolor: "#4417c6",
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300
                    }}
                    variant="outlined"
                    onClick={handleClickExtrasOpen}
                >
                    <Typography sx={{ fontSize: 14 }}>
                        Extra's Fund
                        <Typography sx={{ fontSize: 14 }}>{currencyFormat(extrasAmount)} Left</Typography>
                    </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={extrasOpen}
                onClose={handleExtrasClose}
                TransitionComponent={Slide}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleExtrasClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Extra's
                        </Typography>
                    </Toolbar>
                </AppBar>
                <ExtrasTable />
            </Dialog>
        </div>
    );
};

export default ExtrasButton;
