import React, {forwardRef, useEffect, useState} from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import {withAuthenticator} from "@aws-amplify/ui-react";
import Home from "./Home";
import {
    AppBar,
    BottomNavigation,
    BottomNavigationAction,
    Dialog,
    IconButton,
    Paper,
    Slide,
    Toolbar,
    Typography
} from "@mui/material";
import Face2Icon from "@mui/icons-material/Face2";
import FaceIcon from "@mui/icons-material/Face";
import BriExpenseTable from "./components/BriExpenseTable";
import JustinExpenseTable from "./components/JustinExpenseTable";
import CloseIcon from "@mui/icons-material/Close";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const App = () => {
    const [value, setValue] = useState(0);
    const [active, setActive] = useState("Home");
    const [briExpenseOpen, setBriExpenseOpen] = useState(false);
    const [justinExpenseOpen, setJustinExpenseOpen] = useState(false);

    const navStyle = { backgroundColor: '#262450' };
    const homeStyle = { backgroundColor: '#19173D', display: 'flex', flexDirection: 'column', minHeight: '100vh' };

    useEffect(() => {
        if (active === "Bri") {
            setBriExpenseOpen(true);
            setJustinExpenseOpen(false);
        } else if (active === "Justin") {
            setJustinExpenseOpen(true);
            setBriExpenseOpen(false);
        } else {
            setBriExpenseOpen(false);
            setJustinExpenseOpen(false);
        }
    }, [active]);

    const handleClose = () => {
        setActive("Home");
        setBriExpenseOpen(false);
        setJustinExpenseOpen(false);
    };

    return (
        <div style={homeStyle}>
            <div style={{ flex: 1 }}>
                {active === "Home" && <Home />}
            </div>
            <Dialog
                fullScreen
                open={briExpenseOpen}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Bri's Expenses
                        </Typography>
                    </Toolbar>
                </AppBar>
                <BriExpenseTable />
            </Dialog>
            <Dialog
                fullScreen
                open={justinExpenseOpen}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Justin's Expenses
                        </Typography>
                    </Toolbar>
                </AppBar>
                <JustinExpenseTable />
            </Dialog>

            <Paper sx={{ position: 'sticky', bottom: 0, width: '100%' }}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    style={navStyle}
                    sx={{ "& .MuiBottomNavigationAction-root": { color: '#7b78AA' } }}
                >
                    <BottomNavigationAction type="button" onClick={() => setActive("Bri")} label="Bri" icon={<Face2Icon />} />
                    <BottomNavigationAction type="button" onClick={() => setActive("Justin")} label="Justin" icon={<FaceIcon />} />
                </BottomNavigation>
            </Paper>
        </div>
    );
};

export default withAuthenticator(App);
