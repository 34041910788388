import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from "aws-amplify";
import {
    listBriEntertainmentExpenses,
    listEntertainmentFunds,
    listJustinEntertainmentExpenses
} from "../../graphql/queries";
import {
    createBriEntertainmentExpense,
    createEntertainmentFund,
    createJustinEntertainmentExpense,
    deleteBriEntertainmentExpense,
    deleteEntertainmentFund,
    deleteJustinEntertainmentExpense
} from "../../graphql/mutations";
import { Flex, View } from "@aws-amplify/ui-react";
import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
    calculateBriEntertainmentTotal,
    calculateEntertainmentFundTotal,
    calculateJustinEntertainmentTotal,
    convertDate,
    currencyFormat
} from "../calculationFunctions";
import {BUDGET_AMOUNTS} from "../../budgetAmounts";
import {handleCreateExpense} from "../expenseHelpers";

export default function EntertainmentTable() {
    const [entertainmentBriExpense, setEntertainmentBriExpenses] = useState([]);
    const [entertainmentJustinExpense, setEntertainmentJustinExpenses] = useState([]);
    const [entertainmentFund, setEntertainmentFund] = useState([]);
    const [value, setValue] = useState('1');
    const [entertainmentAmount, setEntertainmentAmount] = useState(0);

    useEffect(() => {
        fetchTotalAmountofEntertainment();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalAmountofEntertainment() {
        const [briApiData, justinApiData, fundApiData] = await Promise.all([
            API.graphql({ query: listBriEntertainmentExpenses }),
            API.graphql({ query: listJustinEntertainmentExpenses }),
            API.graphql({ query: listEntertainmentFunds })
        ]);

        const briExpenses = briApiData.data.listBriEntertainmentExpenses.items;
        const justinExpenses = justinApiData.data.listJustinEntertainmentExpenses.items;
        const fundExpenses = fundApiData.data.listEntertainmentFunds.items;

        setEntertainmentBriExpenses(briExpenses);
        setEntertainmentJustinExpenses(justinExpenses);
        setEntertainmentFund(fundExpenses);

        const totalFund = calculateEntertainmentFundTotal(fundExpenses);
        const totalJustin = calculateJustinEntertainmentTotal(justinExpenses);
        const totalBri = calculateBriEntertainmentTotal(briExpenses);

        setEntertainmentAmount(BUDGET_AMOUNTS.entertainment + totalFund + totalJustin + totalBri);
    }

    async function handleDeleteExpense(id, deleteExpense, updateState) {
        await API.graphql({ query: deleteExpense, variables: { input: { id } } });
        updateState(prev => prev.filter(expense => expense.id !== id));
        fetchTotalAmountofEntertainment();
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Entertainment Fund" value="1" />
                        <Tab label="Bri" value="2" />
                        <Tab label="Justin" value="3" />
                    </TabList>
                </Box>
                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="1">
                    <Typography textAlign="center">Total Entertainment Fund for the month is
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}> {" " + currencyFormat(entertainmentAmount)}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createEntertainmentFund, fetchTotalAmountofEntertainment, { name: "EntertainmentFundName", amount: "EntertainmentFundAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="EntertainmentFundName" placeholder="Name" label="Name" required />
                            <TextField name="EntertainmentFundAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{paddingRight: 0}} align="left">Expense</TableCell>
                                        <TableCell sx={{paddingLeft: .5}} align="left">Day</TableCell>
                                        <TableCell sx={{paddingLeft: 1}} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {entertainmentFund.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{paddingRight: 0}} align="left">{row.EntertainmentFundName}</TableCell>
                                            <TableCell sx={{paddingLeft: .5}} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{paddingLeft: 1}} align="center">{currencyFormat(row.EntertainmentFundAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteEntertainmentFund, setEntertainmentFund)}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="2">
                    <Typography textAlign="center">Total amount Bri has spent
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{" " + currencyFormat(-calculateBriEntertainmentTotal(entertainmentBriExpense))}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createBriEntertainmentExpense, fetchTotalAmountofEntertainment, { name: "BriEntertainmentExpenseName", amount: "BriEntertainmentExpenseAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="BriEntertainmentExpenseName" placeholder="Name" label="Name" required />
                            <TextField name="BriEntertainmentExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{paddingRight: 0}} align="left">Expense</TableCell>
                                        <TableCell sx={{paddingLeft: .5}} align="left">Day</TableCell>
                                        <TableCell sx={{paddingLeft: 1}} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {entertainmentBriExpense.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{paddingRight: 0}} align="left">{row.BriEntertainmentExpenseName}</TableCell>
                                            <TableCell sx={{paddingLeft: .5}} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{paddingLeft: 1}} align="center">{currencyFormat(row.BriEntertainmentExpenseAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteBriEntertainmentExpense, setEntertainmentBriExpenses)}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="3">
                    <Typography textAlign="center">Total amount Justin has spent
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{" " + currencyFormat(-calculateJustinEntertainmentTotal(entertainmentJustinExpense))}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createJustinEntertainmentExpense, fetchTotalAmountofEntertainment, { name: "JustinEntertainmentExpenseName", amount: "JustinEntertainmentExpenseAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="JustinEntertainmentExpenseName" placeholder="Name" label="Name" required />
                            <TextField name="JustinEntertainmentExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{paddingRight: 0}} align="left">Expense</TableCell>
                                        <TableCell sx={{paddingLeft: .5}} align="left">Day</TableCell>
                                        <TableCell sx={{paddingLeft: 1}} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {entertainmentJustinExpense.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{paddingRight: 0}} align="left">{row.JustinEntertainmentExpenseName}</TableCell>
                                            <TableCell sx={{paddingLeft: .5}} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{paddingLeft: 1}} align="center">{currencyFormat(row.JustinEntertainmentExpenseAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteJustinEntertainmentExpense, setEntertainmentJustinExpenses)}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br /></div>
                <Typography textAlign="center">
                    Amount to carry over at the end of the month is
                    <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                        {" " + currencyFormat(entertainmentAmount)}
                    </Typography>
                </Typography>
                <div><br /></div>
                <Typography textAlign="center">***Monthly budget for Entertainment is {currencyFormat(BUDGET_AMOUNTS.entertainment)}</Typography>
            </TabContext>
        </Box>
    );
}
