import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from "aws-amplify";
import {
    listBriIanExpenses,
    listIanFunds,
    listJustinIanExpenses
} from "../../graphql/queries";
import {
    createBriIanExpense,
    createIanFund,
    createJustinIanExpense,
    deleteBriIanExpense,
    deleteIanFund,
    deleteJustinIanExpense
} from "../../graphql/mutations";
import { Flex, View } from "@aws-amplify/ui-react";
import { Button, TextField, Typography } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import {
    calculateBriIanAllowanceTotal,
    calculateIanFundTotal,
    calculateJustinIanAllowanceTotal,
    convertDate,
    currencyFormat
} from '../calculationFunctions';
import { BUDGET_AMOUNTS } from '../../budgetAmounts';
import Box from "@mui/material/Box";
import { handleCreateExpense } from '../expenseHelpers'; // Import the new handleCreateExpense function

export default function IanAllowanceTable() {
    const [ianBriExpense, setIanBriExpenses] = useState([]);
    const [ianJustinExpense, setIanJustinExpenses] = useState([]);
    const [ianFund, setIanFund] = useState([]);
    const [ianAmount, setIanAmount] = useState(0);
    const [value, setValue] = useState('1');

    useEffect(() => {
        fetchTotalIanAllowance();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalIanAllowance() {
        const [briApiData, justinApiData, fundApiData] = await Promise.all([
            API.graphql({ query: listBriIanExpenses }),
            API.graphql({ query: listJustinIanExpenses }),
            API.graphql({ query: listIanFunds })
        ]);

        const briExpenses = briApiData.data.listBriIanExpenses.items;
        const justinExpenses = justinApiData.data.listJustinIanExpenses.items;
        const fundExpenses = fundApiData.data.listIanFunds.items;

        setIanBriExpenses(briExpenses);
        setIanJustinExpenses(justinExpenses);
        setIanFund(fundExpenses);

        const totalBri = calculateBriIanAllowanceTotal(briExpenses);
        const totalJustin = calculateJustinIanAllowanceTotal(justinExpenses);
        const totalFund = calculateIanFundTotal(fundExpenses);

        setIanAmount(BUDGET_AMOUNTS.ianAllowance + totalBri + totalJustin + totalFund);
    }

    async function handleDeleteExpense(id, deleteExpense, updateState) {
        await API.graphql({ query: deleteExpense, variables: { input: { id } } });
        updateState(prev => prev.filter(expense => expense.id !== id));
        fetchTotalIanAllowance();
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Ian's Fund" value="1" />
                        <Tab label="Bri" value="2" />
                        <Tab label="Justin" value="3" />
                    </TabList>
                </Box>
                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="1">
                    <Typography textAlign="center">
                        Ian's Total Fund for the month is
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                            {" " + currencyFormat(ianAmount)}
                        </Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createIanFund, fetchTotalIanAllowance, { name: "IanFundName", amount: "IanFundAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="IanFundName" placeholder="Name" label="Name" required />
                            <TextField name="IanFundAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ianFund.map(row => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.IanFundName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.IanFundAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteIanFund, setIanFund)}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="2">
                    <Typography textAlign="center">
                        Total amount Bri has spent
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                            {" " + currencyFormat(-calculateBriIanAllowanceTotal(ianBriExpense))}
                        </Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createBriIanExpense, fetchTotalIanAllowance, { name: "BriIanExpenseName", amount: "BriIanExpenseAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="BriIanExpenseName" placeholder="Name" label="Name" required />
                            <TextField name="BriIanExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ianBriExpense.map(row => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.BriIanExpenseName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.BriIanExpenseAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteBriIanExpense, setIanBriExpenses)}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="3">
                    <Typography textAlign="center">
                        Total amount Justin has spent
                        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                            {" " + currencyFormat(-calculateJustinIanAllowanceTotal(ianJustinExpense))}
                        </Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createJustinIanExpense, fetchTotalIanAllowance, { name: "JustinIanName", amount: "JustinIanExpenseAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="JustinIanName" placeholder="Name" label="Name" required />
                            <TextField name="JustinIanExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ianJustinExpense.map(row => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.JustinIanName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.JustinIanExpenseAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteJustinIanExpense, setIanJustinExpenses)}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <div><br /></div>
                <Typography textAlign="center">
                    Amount to carry over at the end of the month is
                    <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                        {" " + currencyFormat(ianAmount)}
                    </Typography>
                </Typography>
                <div><br /></div>
                <Typography textAlign="center">*** Monthly budget for Ian's Allowance is {currencyFormat(BUDGET_AMOUNTS.ianAllowance)}</Typography>
            </TabContext>
        </Box>
    );
}
