import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from "aws-amplify";
import { listJustinExpenses } from "../graphql/queries";
import { createJustinExpense, deleteJustinExpense } from "../graphql/mutations";
import { Flex, View } from "@aws-amplify/ui-react";
import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { calculateJustinExpenseTotal, convertDate, currencyFormat } from "./calculationFunctions";
import { BUDGET_AMOUNTS } from "../budgetAmounts";

export default function JustinExpenseTable() {
    const [justinExpenses, setJustinExpenses] = useState([]);
    const [value, setValue] = useState('1');
    const [justinExpenseAmount, setJustinExpenseAmount] = useState(0);
    const [amountToCarryOver, setAmountToCarryOver] = useState(0);

    useEffect(() => {
        fetchTotalAmountofJustinExpense();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalAmountofJustinExpense() {
        const justinApiData = await API.graphql({ query: listJustinExpenses });
        const amountsFromJustinAPI = justinApiData.data.listJustinExpenses.items;
        setJustinExpenses(amountsFromJustinAPI);

        const totalJustinExpense = calculateJustinExpenseTotal(amountsFromJustinAPI);
        setJustinExpenseAmount(totalJustinExpense);
        setAmountToCarryOver(BUDGET_AMOUNTS.justinAllowance + totalJustinExpense);
    }

    async function createNewJustinExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            JustinExpenseName: form.get("JustinExpenseName"),
            JustinExpenseAmount: form.get("JustinExpenseAmount"),
        };
        await API.graphql({
            query: createJustinExpense,
            variables: { input: data },
        });
        await fetchTotalAmountofJustinExpense();
        event.target.reset();
    }

    async function deleteOldJustinExpense({ id }) {
        const newExpense = justinExpenses.filter((expense) => expense.id !== id);
        setJustinExpenses(newExpense);
        await API.graphql({
            query: deleteJustinExpense,
            variables: { input: { id } },
        });
        await fetchTotalAmountofJustinExpense();
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Justin" value="1" />
                    </TabList>
                </Box>
                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="1">
                    <View as="form" onSubmit={createNewJustinExpense}>
                        <Typography sx={{ margin: 2 }}>Justin has {currencyFormat(justinExpenseAmount)} Left</Typography>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField
                                name="JustinExpenseName"
                                placeholder="Name"
                                label="Name"
                                required
                            />
                            <TextField
                                name="JustinExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                required
                            />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ paddingRight: 0 }}>Expense</TableCell>
                                        <TableCell align="left" sx={{ paddingLeft: .5 }}>Created</TableCell>
                                        <TableCell align="center" sx={{ paddingLeft: 1 }}>Amount</TableCell>
                                        <TableCell sx={{paddingLeft: .5}} align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {justinExpenses.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell align="left" sx={{ paddingRight: 0 }}>{row.JustinExpenseName}</TableCell>
                                            <TableCell align="left" sx={{ paddingLeft: .5 }}>{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center" sx={{ paddingLeft: 1 }}>{currencyFormat(row.JustinExpenseAmount)}</TableCell>
                                            <TableCell sx={{paddingLeft: .5}} align="center">
                                                <Button variant="contained" onClick={() => deleteOldJustinExpense(row)}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
            </TabContext>
            <Typography textAlign="center">Amount to carry over at the end of the month is
                <Typography sx={{ fontWeight: 'bold', display: 'inline' }}> {currencyFormat(amountToCarryOver)}</Typography>
            </Typography>
            <Typography textAlign="center">***Monthly budget for Justin's Money is {currencyFormat(BUDGET_AMOUNTS.justinAllowance)}</Typography>
        </Box>
    );
}
