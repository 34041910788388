import React, { useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import EntertainmentButton from "./components/Entertainment/EntertainmentButton";
import GroceriesButton from "./components/Groceries/GroceriesButton";
import GasButton from "./components/Gas/GasButton";
import ExtrasButton from "./components/Extras/ExtrasButton";
import IanAllowanceButton from "./components/IanAllowance/IanAllowanceButton";
import EmergencySavingsButton from "./components/EmergencySavings/EmergencySavingsButton";
import BillsButton from "./components/Bills/BillsButton";
import TravelButton from "./components/Travel/TravelButton";
import { BUDGET_AMOUNTS } from "./budgetAmounts";
import PlaidLinkComponent from "./PlaidLinkComponent";


const buttonConfig = [
    {
        name: "Groceries",
        component: GroceriesButton,
        budgetKey: "groceries"
    },
    {
        name: "Entertainment",
        component: EntertainmentButton,
        budgetKey: "entertainment"
    },
    {
        name: "Gas",
        component: GasButton,
        budgetKey: "gas"
    },
    {
        name: "Ian's Allowance",
        component: IanAllowanceButton,
        budgetKey: "ianAllowance"
    },
    {
        name: "Bills",
        component: BillsButton,
        budgetKey: "bills"
    },
    {
        name: "Extras",
        component: ExtrasButton,
        budgetKey: "extras"
    },
    {
        name: "Travel",
        component: TravelButton,
        budgetKey: "travel"
    },
    {
        name: "Emergency Savings",
        component: EmergencySavingsButton,
        budgetKey: "emergency"
    },
    {
        name: "Bank Link",
        component: PlaidLinkComponent,
        budgetKey: ""
    }
];


const Home = () => {
    const [openStates, setOpenStates] = useState({
        Groceries: false,
        Entertainment: false,
        Gas: false,
        "Ian's Allowance": false,
        Bills: false,
        Extras: false,
        Travel: false,
        "EmergencySavings": false
    });

    const [budgetAmounts, setBudgetAmounts] = useState({
        groceries: BUDGET_AMOUNTS.groceries,
        entertainment: BUDGET_AMOUNTS.entertainment,
        gas: BUDGET_AMOUNTS.gas,
        ianAllowance: BUDGET_AMOUNTS.ianAllowance,
        bills: BUDGET_AMOUNTS.bills,
        extras: BUDGET_AMOUNTS.extras,
        travel: BUDGET_AMOUNTS.travel,
        emergency: BUDGET_AMOUNTS.emergency
    });

    const handleOpen = (name) => {
        setOpenStates(prev => ({ ...prev, [name]: true }));
    };

    const handleClose = (name) => {
        setOpenStates(prev => ({ ...prev, [name]: false }));
    };

    const handleUpdate = (name, newAmount) => {
        setBudgetAmounts(prev => ({ ...prev, [name]: newAmount }));
    };

    return (
        <div>
            {buttonConfig.map(({ name, component: ButtonComponent, budgetKey }) => (
                <ButtonComponent
                    key={name}
                    budgetAmount={budgetAmounts[budgetKey]}
                    open={openStates[name]}
                    onOpen={() => handleOpen(name)}
                    onClose={() => handleClose(name)}
                    onUpdate={(newAmount) => handleUpdate(budgetKey, newAmount)}
                />
            ))}
        </div>

    );
};

export default Home;
