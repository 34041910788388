import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from "aws-amplify";
import { listBriExpenses } from "../graphql/queries";
import {
    createBriExpense,
    deleteBriExpense,
} from "../graphql/mutations";
import { Flex, View } from "@aws-amplify/ui-react";
import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { calculateBriExpenseTotal, convertDate, currencyFormat } from "./calculationFunctions";
import { BUDGET_AMOUNTS } from "../budgetAmounts";

export default function BriExpenseTable() {
    const [briExpenses, setBriExpenses] = useState([]);
    const [value, setValue] = useState('1');
    const [briExpenseAmount, setBriExpenseAmount] = useState(0);
    const [amountToCarryOver, setAmountToCarryOver] = useState(0);

    useEffect(() => {
        fetchTotalAmountofBriExpense();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalAmountofBriExpense() {
        const briApiData = await API.graphql({ query: listBriExpenses });
        const amountsFromBriAPI = briApiData.data.listBriExpenses.items;
        setBriExpenses(amountsFromBriAPI);

        const totalBriExpense = calculateBriExpenseTotal(amountsFromBriAPI);
        setBriExpenseAmount(totalBriExpense);
        setAmountToCarryOver(BUDGET_AMOUNTS.briAllowance + totalBriExpense);
    }

    async function createNewBriExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            BriExpenseName: form.get("BriExpenseName"),
            BriExpenseAmount: form.get("BriExpenseAmount"),
        };
        await API.graphql({
            query: createBriExpense,
            variables: { input: data },
        });
        await fetchTotalAmountofBriExpense();
        event.target.reset();
    }

    async function deleteOldBriExpense({ id }) {
        const newExpense = briExpenses.filter((expense) => expense.id !== id);
        setBriExpenses(newExpense);
        await API.graphql({
            query: deleteBriExpense,
            variables: { input: { id } },
        });
        await fetchTotalAmountofBriExpense();
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Bri" value="1" />
                    </TabList>
                </Box>
                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="1">
                    <View as="form" onSubmit={createNewBriExpense}>
                        <Typography sx={{ margin: 2 }}>Bri has {currencyFormat(briExpenseAmount)} Left</Typography>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField
                                name="BriExpenseName"
                                placeholder="Name"
                                label="Name"
                                required
                            />
                            <TextField
                                name="BriExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                required
                            />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ paddingRight: 0 }}>Expense</TableCell>
                                        <TableCell align="left" sx={{ paddingLeft: .5 }}>Created</TableCell>
                                        <TableCell align="center" sx={{ paddingLeft: 1 }}>Amount</TableCell>
                                        <TableCell sx={{paddingLeft: .5}} align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {briExpenses.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell align="left" sx={{ paddingRight: 0 }}>{row.BriExpenseName}</TableCell>
                                            <TableCell align="left" sx={{ paddingLeft: .5 }}>{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center" sx={{ paddingLeft: 1 }}>{currencyFormat(row.BriExpenseAmount)}</TableCell>
                                            <TableCell sx={{paddingLeft: .5}} align="center">
                                                <Button variant="contained" onClick={() => deleteOldBriExpense(row)}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
            </TabContext>
            <Typography textAlign="center">Amount to carry over at the end of the month is
                <Typography sx={{ fontWeight: 'bold', display: 'inline' }}> {currencyFormat(amountToCarryOver)}</Typography>
            </Typography>
            <Typography textAlign="center">***Monthly budget for Bri's Money is {currencyFormat(BUDGET_AMOUNTS.briAllowance)}</Typography>
        </Box>
    );
}
