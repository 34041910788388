import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from "aws-amplify";
import { Flex, View } from "@aws-amplify/ui-react";
import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
    calculateBriEmergencyExpenseTotal,
    calculateEmergencyExpenseTotal,
    calculateJustinEmergencyExpenseTotal,
    convertDate,
    currencyFormat
} from "../calculationFunctions";
import {
    createBriEmergencyExpense,
    createEmergencySavingsAmount,
    createJustinEmergencyExpense,
    deleteBriEmergencyExpense,
    deleteEmergencySavingsAmount,
    deleteJustinEmergencyExpense
} from "../../graphql/mutations";
import { listBriEmergencyExpenses, listEmergencySavingsAmounts, listJustinEmergencyExpenses } from "../../graphql/queries";
import { BUDGET_AMOUNTS } from "../../budgetAmounts";
import { handleCreateExpense } from "../expenseHelpers";
import Tab from "@mui/material/Tab";  // Import the dynamic create helper

export default function EmergencySavingsTable() {
    const [savingsExpense, setSavingsExpenses] = useState([]);
    const [briEmergencyExpense, setBriEmergencyExpenses] = useState([]);
    const [justinEmergencyExpense, setJustinEmergencyExpenses] = useState([]);
    const [finalTotalAmount, setFinalTotalAmount] = useState(0);
    const [value, setValue] = useState('1');

    useEffect(() => {
        fetchEmergencySavingsItems();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchEmergencySavingsItems() {
        try {
            const [savingsData, briData, justinData] = await Promise.all([
                API.graphql({ query: listEmergencySavingsAmounts }),
                API.graphql({ query: listBriEmergencyExpenses }),
                API.graphql({ query: listJustinEmergencyExpenses })
            ]);

            const savingsAmounts = savingsData.data.listEmergencySavingsAmounts.items;
            const briExpenses = briData.data.listBriEmergencyExpenses.items;
            const justinExpenses = justinData.data.listJustinEmergencyExpenses.items;

            setSavingsExpenses(savingsAmounts);
            setBriEmergencyExpenses(briExpenses);
            setJustinEmergencyExpenses(justinExpenses);

            const totalAmount = calculateEmergencyExpenseTotal(savingsAmounts);
            const totalBriAmount = calculateBriEmergencyExpenseTotal(briExpenses);
            const totalJustinAmount = calculateJustinEmergencyExpenseTotal(justinExpenses);

            setFinalTotalAmount(BUDGET_AMOUNTS.emergency + totalAmount + totalBriAmount + totalJustinAmount);
        } catch (error) {
            console.error("Error fetching emergency savings data:", error);
        }
    }

    async function handleDeleteExpense(id, deleteExpense, updateState) {
        await API.graphql({ query: deleteExpense, variables: { input: { id } } });
        updateState((prev) => prev.filter((expense) => expense.id !== id));
        fetchEmergencySavingsItems();
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Emergency Fund" value="1" />
                        <Tab label="Bri" value="2" />
                        <Tab label="Justin" value="3" />
                    </TabList>
                </Box>

                {/* Emergency Fund Tab */}
                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="1">
                    <Typography textAlign="center">
                        Total Savings <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{currencyFormat(finalTotalAmount)}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createEmergencySavingsAmount, fetchEmergencySavingsItems, { name: "EmergencySavingsName", amount: "totalEmergencySavings" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="EmergencySavingsName" placeholder="Name" label="Name" required />
                            <TextField name="totalEmergencySavings" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {savingsExpense.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.EmergencySavingsName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.totalEmergencySavings)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteEmergencySavingsAmount, setSavingsExpenses)}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                {/* Bri Tab */}
                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="2">
                    <Typography textAlign="center">
                        Total Bri Savings <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{currencyFormat(calculateBriEmergencyExpenseTotal(briEmergencyExpense))}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createBriEmergencyExpense, fetchEmergencySavingsItems, { name: "BriEmergencyExpenseName", amount: "BriEmergencyExpenseAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="BriEmergencyExpenseName" placeholder="Name" label="Name" required />
                            <TextField name="BriEmergencyExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {briEmergencyExpense.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.BriEmergencyExpenseName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.BriEmergencyExpenseAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteBriEmergencyExpense, setBriEmergencyExpenses)}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                {/* Justin Tab */}
                <TabPanel sx={{ padding: 0, marginTop: 3 }} value="3">
                    <Typography textAlign="center">
                        Total Justin Savings <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{currencyFormat(calculateJustinEmergencyExpenseTotal(justinEmergencyExpense))}</Typography>
                    </Typography>
                    <View as="form" onSubmit={(e) => handleCreateExpense(e, createJustinEmergencyExpense, fetchEmergencySavingsItems, { name: "JustinEmergencyExpenseName", amount: "JustinEmergencyExpenseAmount" })}>
                        <Flex margin="1rem" direction="row" justifyContent="center">
                            <TextField name="JustinEmergencyExpenseName" placeholder="Name" label="Name" required />
                            <TextField name="JustinEmergencyExpenseAmount" placeholder="Amount" label="Amount" required />
                            <Button type="submit" variant="contained">Add</Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingRight: 0 }} align="left">Expense</TableCell>
                                        <TableCell sx={{ paddingLeft: .5 }} align="left">Day</TableCell>
                                        <TableCell sx={{ paddingLeft: 1 }} align="center">Amount</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {justinEmergencyExpense.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ paddingRight: 0 }} align="left">{row.JustinEmergencyExpenseName}</TableCell>
                                            <TableCell sx={{ paddingLeft: .5 }} align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell sx={{ paddingLeft: 1 }} align="center">{currencyFormat(row.JustinEmergencyExpenseAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => handleDeleteExpense(row.id, deleteJustinEmergencyExpense, setJustinEmergencyExpenses)}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>

                <div><br /></div>
                <Typography textAlign="center">
                    Amount to carry over at the end of the month is
                    <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                        {" " + currencyFormat(finalTotalAmount)}
                    </Typography>
                </Typography>
                <div><br /></div>
                <Typography textAlign="center">***Monthly contribution to Emergency Savings is {currencyFormat(BUDGET_AMOUNTS.emergency)}</Typography>
            </TabContext>
        </Box>
    );
}
